import { lazy } from 'react';

import { MyAccount as AdvoxStrigoB2BMyAccount } from 'AdvoxStrigoB2BRoute/MyAccount/MyAccount.component';
import { CREDITS_HISTORY } from 'Type/Account';

export * from 'AdvoxStrigoB2BRoute/MyAccount/MyAccount.component';

export const MyAccountCreditsHistory = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "account-credits" */
        'Component/MyAccountCreditsHistory'
    )
);

/** @namespace AdvoxLoyaltyProgram/Route/MyAccount/Component/MyAccount */
export class MyAccount extends AdvoxStrigoB2BMyAccount {
    renderMap = {
        ...this.renderMap,
        [CREDITS_HISTORY]: MyAccountCreditsHistory,
    };
}

export default MyAccount;
