import { GET_CONTRACTOR } from 'AdvoxStrigoB2BStore/Contractor/Contractor.actions';

/** @namespace AdvoxLoyaltyProgram/Store/Contractor/Reducer/getInitialState */
export const getInitialState = () => ({
    contractor: {
        can_place_direct_order: true,
    },
});

/** @namespace AdvoxLoyaltyProgram/Store/Contractor/Reducer/ContractorReducer */
export const ContractorReducer = (state = getInitialState(), action) => {
    const { type, contractor } = action;

    switch (type) {
        case GET_CONTRACTOR:
            return {
                contractor,
            };

        default:
            return state;
    }
};

export default ContractorReducer;
