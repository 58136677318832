import { LOYALTY_CURRENCY } from 'Util/Currency';

export * from 'AdvoxStrigoB2BUtil/Price/Price.config';
export * from 'AdvoxStrigoB2BUtil/Price/Price';

/** @namespace AdvoxLoyaltyProgram/Util/Price/formatPrice */
export const formatPrice = (price) => {
    if (price === undefined) {
        return null;
    }

    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: LOYALTY_CURRENCY,
        maximumFractionDigits: 0,
    }).format(price);
};
