import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
    BrandPage,
    BrandsPage,
    DemoNotice,
    Header,
    NewVersionPopup,
    NotificationList,
    Router as AdvoxBasicRouter,
} from 'AdvoxBasicComponent/Router/Router.component';
import { AFTER_ITEMS_TYPE, BEFORE_ITEMS_TYPE, CREATE_ACCOUNT, SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';

/** @namespace AdvoxLoyaltyProgram/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export { BrandsPage, DemoNotice, Header, NewVersionPopup, NotificationList, BrandPage };

export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/NoMatch'));

/** @namespace AdvoxLoyaltyProgram/Component/Router/Component/Router */
export class Router extends AdvoxBasicRouter {
    [SWITCH_ITEMS_TYPE] = [
        ...this[SWITCH_ITEMS_TYPE],
        {
            component: <Route path={withStoreRegex('/account/create')} render={(props) => <NoMatch {...props} />} />,
            position: 61,
            name: CREATE_ACCOUNT,
        },
    ];

    renderDefaultRouterContent() {
        if (location.pathname.match('/program_wpc_pl/account/create')) {
            return (
                <>
                    {this.renderSectionOfType(BEFORE_ITEMS_TYPE)}
                    <Route path={withStoreRegex('/:account*/create/')} render={(props) => <NoMatch {...props} />} />
                    {this.renderSectionOfType(AFTER_ITEMS_TYPE)}
                </>
            );
        }

        return (
            <>
                {this.renderSectionOfType(BEFORE_ITEMS_TYPE)}
                {this.renderMainItems()}
                {this.renderSectionOfType(AFTER_ITEMS_TYPE)}
            </>
        );
    }
}

export default Router;
