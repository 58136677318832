import { Suspense } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountContainer as AdvoxStrigoB2BMyAccountContainer,
} from 'AdvoxStrigoB2BRoute/MyAccount/MyAccount.container';
import Loader from 'Component/Loader';
import MyAccount from 'Route/MyAccount/MyAccount.component';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import OrderReducer from 'Store/Order/Order.reducer';
import { CREDITS_HISTORY, DASHBOARD, MY_ORDERS } from 'Type/Account';
import { withReducers } from 'Util/DynamicReducer';
import { appendWithStoreCode } from 'Util/Url';

export * from 'AdvoxStrigoB2BRoute/MyAccount/MyAccount.container';

/** @namespace AdvoxLoyaltyProgram/Route/MyAccount/Container/MyAccountContainer */
export class MyAccountContainer extends AdvoxStrigoB2BMyAccountContainer {
    static tabMap = {
        ...AdvoxStrigoB2BMyAccountContainer.tabMap,
        [CREDITS_HISTORY]: {
            url: '/credits-history',
            name: __('Credits history'),
            iconName: 'coins',
            permissions: [],
        },
        [MY_ORDERS]: {
            url: '/my-orders',
            name: __('Orders'),
            iconName: 'orders',
            permissions: [],
        },
    };

    // needed to override these methods to override tabMap obj
    static getDerivedStateFromProps(props, state) {
        return MyAccountContainer.navigateToSelectedTab(props, state);
    }

    tabsFilterEnabled() {
        return Object.fromEntries(
            Object.entries(MyAccountContainer.tabMap).filter(([k]) => MyAccountContainer.isTabEnabled(this.props, k))
        );
    }

    updateBreadcrumbs() {
        const { updateBreadcrumbs } = this.props;
        const { activeTab } = this.state;
        const { url, name } = MyAccountContainer.tabMap[activeTab];

        updateBreadcrumbs([
            { url: `${MY_ACCOUNT_URL}${url}`, name },
            { name: __('My Account'), url: `${MY_ACCOUNT_URL}/${DASHBOARD}` },
        ]);
    }

    changeActiveTab(activeTab) {
        const { history } = this.props;
        const {
            [activeTab]: { url },
        } = this.tabsFilterEnabled(MyAccountContainer.tabMap);

        history.push(appendWithStoreCode(`${MY_ACCOUNT_URL}${url}`));
        this.changeHeaderState(activeTab);
    }

    render() {
        return (
            <Suspense fallback={<Loader />}>
                <MyAccount
                    {...this.containerProps()}
                    {...this.containerFunctions}
                    tabMap={this.tabsFilterEnabled(MyAccountContainer.tabMap)}
                />
            </Suspense>
        );
    }
}

export default withReducers({
    OrderReducer,
})(connect(mapStateToProps, mapDispatchToProps)(MyAccountContainer));
