import { getFormattedDate, getInitialState, orderList, ORDERS } from 'SourceStore/Order/Order.reducer';
import { GET_ORDER_LIST } from 'Store/Order/Order.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

export { ORDERS, getFormattedDate, orderList, getInitialState };

/** @namespace AdvoxLoyaltyProgram/Store/Order/Reducer/formatOrders */
export const formatOrders = (orders) =>
    orders.reduce((acc, order) => {
        const { base_order_info } = order;
        const { created_at } = base_order_info;
        const formattedDate = getFormattedDate(created_at);

        return [
            ...acc,
            {
                ...order,
                base_order_info: {
                    ...order.base_order_info,
                    created_at: formattedDate,
                },
            },
        ];
    }, []);

/** @namespace AdvoxLoyaltyProgram/Store/Order/Reducer/OrderReducer */
export const OrderReducer = (state = getInitialState(), action) => {
    const { type, orderList, status } = action;

    switch (type) {
        case GET_ORDER_LIST:
            const { items = [] } = orderList;
            const formattedOrders = formatOrders(items);

            BrowserDatabase.setItem(formattedOrders, ORDERS, ONE_MONTH_IN_SECONDS);

            return {
                ...state,
                isLoading: status,
                orderList: formattedOrders,
            };

        default:
            return state;
    }
};

export default OrderReducer;
